<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Magnifying Glass List'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 53 54"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="M50.6406 40.9931L39.5913 29.9437C40.8194 27.27 41.5 24.3469 41.5 21.375C41.5 10.0031 32.2469 0.75 20.875 0.75C9.50313 0.75 0.25 10.0031 0.25 21.375C0.25 32.7469 9.50313 42 20.875 42C23.8431 42 26.7644 41.3194 29.4363 40.0931L40.4894 51.1444C41.8263 52.4831 43.6769 53.25 45.565 53.25C47.4794 53.25 49.2831 52.5037 50.635 51.15C51.9963 49.8037 52.7463 48.0075 52.7519 46.095C52.7537 44.1694 52.0056 42.3581 50.6406 40.9931ZM4 21.375C4 12.0694 11.5712 4.5 20.875 4.5C25.855 4.5 30.325 6.6825 33.4169 10.125H15.25C14.215 10.125 13.375 10.9631 13.375 12V36.4688C7.82875 33.7012 4 27.9844 4 21.375ZM17.125 37.815V13.875H35.875C35.905 13.875 35.9312 13.86 35.9613 13.8581C36.8294 15.5925 37.4162 17.49 37.6394 19.5H30.25C29.215 19.5 28.375 20.3381 28.375 21.375C28.375 22.4119 29.215 23.25 30.25 23.25H37.6319C37.4125 25.2131 36.8369 27.1162 35.9575 28.875H30.25C29.215 28.875 28.375 29.7131 28.375 30.75C28.375 31.7869 29.215 32.625 30.25 32.625H33.3925C32.1175 34.0444 30.6344 35.2687 28.9356 36.1931C26.485 37.5394 23.6969 38.25 20.875 38.25C19.585 38.25 18.3325 38.0906 17.125 37.815ZM47.9875 48.4931C46.6956 49.7831 44.4269 49.7831 43.1388 48.4931L32.8019 38.1581C34.6825 36.8231 36.3156 35.19 37.6525 33.3112L47.9875 43.6444C48.6419 44.3006 49.0019 45.1669 49 46.0856C48.9981 46.9931 48.64 47.8462 47.9875 48.4931Z"
    fill={fillColor}
  />
  <path
    d="M21.4248 22.7011C21.7905 23.0667 22.2705 23.2505 22.7505 23.2505C23.2305 23.2505 23.7105 23.0667 24.0761 22.7011L27.8261 18.9511C28.5592 18.218 28.5592 17.033 27.8261 16.2998C27.093 15.5667 25.908 15.5667 25.1748 16.2998L22.7505 18.7242L22.2011 18.1748C21.468 17.4417 20.283 17.4417 19.5498 18.1748C18.8167 18.908 18.8167 20.093 19.5498 20.8261L21.4248 22.7011Z"
    fill={fillColor}
  />
  <path
    d="M27.8261 25.6748C27.093 24.9417 25.908 24.9417 25.1748 25.6748L22.7505 28.0992L22.2011 27.5498C21.468 26.8167 20.283 26.8167 19.5498 27.5498C18.8167 28.283 18.8167 29.468 19.5498 30.2011L21.4248 32.0761C21.7905 32.4417 22.2705 32.6255 22.7505 32.6255C23.2305 32.6255 23.7105 32.4417 24.0761 32.0761L27.8261 28.3261C28.5592 27.593 28.5592 26.408 27.8261 25.6748Z"
    fill={fillColor}
  />
</svg>
