<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Steering Wheel'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 61 60"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="M30.4978 7.15234C17.8986 7.15234 7.64844 17.4026 7.64844 30.0017C7.64844 42.6008 17.8986 52.851 30.4978 52.851C43.0969 52.851 53.3471 42.6008 53.3471 30.0017C53.3471 17.4026 43.0969 7.15234 30.4978 7.15234ZM30.4978 10.6676C39.9583 10.6676 47.8527 17.4982 49.5106 26.4864C48.4071 26.3164 41.8769 27.5273 36.9074 22.2316C34.0957 19.235 30.3913 19.4331 30.4969 19.4557C28.0437 19.4557 25.7676 20.4417 24.0882 22.2315C21.5509 24.9354 17.879 26.4863 14.0141 26.4863H11.4849C13.1428 17.4982 21.0372 10.6676 30.4978 10.6676ZM12.5698 37.2375C19.3826 35.8035 25.9129 41.5141 25.183 48.5927C19.4427 46.9493 14.7876 42.7126 12.5698 37.2375ZM28.6462 49.2467C29.839 39.5812 20.9343 31.7155 11.5518 33.8653C11.2977 32.6165 11.1637 31.3245 11.1637 30.0017C12.3281 29.8164 20.4688 31.2256 26.6518 24.637C28.7298 22.4221 32.2497 22.4049 34.344 24.6371C40.5328 31.232 48.6396 29.812 49.8319 30.0017C49.8319 31.3245 49.698 32.6165 49.4438 33.8653C40.0422 31.7115 31.1592 39.6016 32.3495 49.2467C31.1523 49.3609 29.9372 49.3698 28.6462 49.2467ZM35.8125 48.5927C35.0818 41.5063 41.6211 35.8051 48.4258 37.2374C46.208 42.7126 41.5529 46.9493 35.8125 48.5927Z"
    fill={fillColor}
  />
  <path
    d="M35.7685 31.7573C35.7685 28.8498 33.4032 26.4844 30.4956 26.4844C27.588 26.4844 25.2227 28.8498 25.2227 31.7573C25.2227 34.6648 27.588 37.0302 30.4956 37.0302C33.4032 37.0302 35.7685 34.6649 35.7685 31.7573ZM30.4956 33.5149C29.5264 33.5149 28.7379 32.7266 28.7379 31.7573C28.7379 30.7881 29.5264 29.9997 30.4956 29.9997C31.4649 29.9997 32.2532 30.7881 32.2532 31.7573C32.2532 32.7266 31.4649 33.5149 30.4956 33.5149Z"
    fill={fillColor}
  />
  <path
    d="M51.6839 8.81414C46.0026 3.13274 38.4783 0.00390625 30.4971 0.00390625C13.9799 0.00390625 0.5 13.4849 0.5 30.001C0.5 46.5182 13.981 59.998 30.4971 59.998C38.4783 59.998 46.0026 56.8692 51.6839 51.1878C63.4367 39.4351 63.4407 20.571 51.6839 8.81414ZM30.4971 56.4828C15.8949 56.4828 4.01528 44.6031 4.01528 30.001C4.01528 15.3988 15.8949 3.51919 30.4971 3.51919C45.0992 3.51919 56.9789 15.3988 56.9789 30.001C56.9789 44.6031 45.0992 56.4828 30.4971 56.4828Z"
    fill={fillColor}
  />
</svg>
