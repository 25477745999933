<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Wheel Moving'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 61 60"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g clip-path="url(#clip0_1710_488)">
    <path
      d="M34.9063 4.40625H11.2565C10.183 4.40625 9.31263 5.2766 9.31263 6.35016C9.31263 7.42372 10.183 8.29407 11.2565 8.29407H21.3612C19.2293 9.62919 17.3071 11.2689 15.6565 13.1527H2.44391C1.37035 13.1528 0.5 14.0233 0.5 15.0968C0.5 16.1704 1.37035 17.0407 2.44391 17.0407H12.8443C10.6016 20.8442 9.31252 25.2741 9.31252 29.9999C9.31252 35.4988 11.0562 40.5982 14.0189 44.7746H8.50334C7.42978 44.7746 6.55943 45.645 6.55943 46.7185C6.55943 47.7921 7.42978 48.6624 8.50334 48.6624H17.4118C18.6239 49.7994 19.9464 50.8196 21.3612 51.7057H2.64442C1.57086 51.7057 0.700508 52.576 0.700508 53.6496C0.700508 54.7231 1.57086 55.5935 2.64442 55.5935H34.9063C49.0189 55.5935 60.5 44.1121 60.5 29.9998C60.5 15.8875 49.0188 4.40625 34.9063 4.40625ZM34.9063 51.7059C22.9377 51.7059 13.2006 41.9688 13.2006 30C13.2006 18.0313 22.9377 8.29419 34.9063 8.29419C46.8749 8.29419 56.6121 18.0313 56.6121 29.9999C56.6121 41.9685 46.8751 51.7059 34.9063 51.7059Z"
      fill={fillColor}
    />
    <path
      d="M34.9055 14.1211C26.1502 14.1211 19.0273 21.244 19.0273 29.9992C19.0273 35.2203 21.5608 39.8606 25.4627 42.7563C25.4985 42.7865 25.534 42.817 25.5725 42.8447C25.6159 42.8763 25.6603 42.9057 25.7052 42.9331C28.3025 44.786 31.4789 45.8772 34.9055 45.8772C38.332 45.8772 41.5083 44.786 44.1058 42.9331C44.1508 42.9057 44.195 42.8763 44.2384 42.8447C44.2768 42.8167 44.3125 42.7863 44.3482 42.7563C48.25 39.8606 50.7836 35.2204 50.7836 29.9992C50.7836 21.2441 43.6607 14.1211 34.9055 14.1211ZM36.8494 18.1675C40.643 18.7887 43.8433 21.1957 45.5552 24.4949L40.5213 26.1306C39.647 24.8651 38.3566 23.909 36.8495 23.46V18.1675H36.8494ZM37.8404 29.9991C37.8404 31.6175 36.5238 32.9341 34.9055 32.9341C33.2871 32.9341 31.9705 31.6176 31.9705 29.9991C31.9705 28.3807 33.2871 27.0641 34.9055 27.0641C36.5238 27.0641 37.8404 28.3807 37.8404 29.9991ZM32.9614 18.1675V23.46C31.454 23.909 30.1639 24.8651 29.2895 26.1306L24.2557 24.4949C25.9678 21.1957 29.1681 18.7886 32.9614 18.1675ZM26.382 38.4234C24.2401 36.2563 22.9153 33.2796 22.9153 29.9991C22.9153 29.3848 22.9619 28.7812 23.0515 28.1915L28.0869 29.8276C28.0855 29.8848 28.0824 29.9416 28.0824 29.9991C28.0824 31.5572 28.6086 32.9942 29.491 34.1441L26.382 38.4234ZM34.9055 41.9893C32.9717 41.9893 31.1439 41.5287 29.5248 40.712L32.6345 36.4321C33.3453 36.6838 34.1094 36.8222 34.9055 36.8222C35.7015 36.8222 36.4656 36.6838 37.1764 36.4321L40.2861 40.712C38.6671 41.5285 36.8392 41.9893 34.9055 41.9893ZM43.4288 38.4234L40.3198 34.1443C41.2022 32.9943 41.7284 31.5573 41.7284 29.9992C41.7284 29.9417 41.7256 29.885 41.7239 29.8278L46.7594 28.1916C46.8489 28.7813 46.8955 29.3849 46.8955 29.9992C46.8956 33.2796 45.571 36.2562 43.4288 38.4234Z"
      fill={fillColor}
    />
  </g>
</svg>
