<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Guard Check'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 61 60"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="M57.2333 12.6043L43.8761 0.486328C43.5311 0.17332 43.0821 0 42.6162 0H18.3807C17.9149 0 17.4658 0.17332 17.1209 0.486328L3.76367 12.6043C3.37191 12.9596 3.14844 13.464 3.14844 13.993C3.14844 23.4305 5.66809 32.6931 10.435 40.7794C15.0614 48.6274 21.6745 55.1865 29.5596 59.748C29.85 59.9161 30.1742 60 30.4985 60C30.8227 60 31.147 59.916 31.4374 59.748C39.3223 55.1865 45.9355 48.6274 50.5618 40.7794C55.3286 32.6931 57.8483 23.4305 57.8483 13.993C57.8484 13.464 57.625 12.9596 57.2333 12.6043ZM30.4985 55.9448C16.1472 47.2093 7.18988 31.6059 6.90547 14.8172L19.1045 3.75H41.8925L54.0915 14.8171C53.807 31.6058 44.8497 47.2093 30.4985 55.9448ZM39.9655 6.86719H21.0315C20.5657 6.86719 20.1166 7.04051 19.7717 7.35352L10.7291 15.5572C10.305 15.9418 10.08 16.4993 10.118 17.0706C10.5702 23.853 12.5661 30.5678 15.89 36.4889C19.2039 42.3925 23.8808 47.585 29.4147 51.5051C29.7395 51.7351 30.1189 51.8501 30.4986 51.8501C30.8783 51.8501 31.2577 51.7351 31.5825 51.5051C37.1164 47.585 41.7931 42.3925 45.1071 36.4889C48.431 30.5678 50.4269 23.853 50.8789 17.0706C50.917 16.4993 50.692 15.9418 50.2679 15.5572L41.2252 7.35352C40.8804 7.04051 40.4312 6.86719 39.9655 6.86719ZM30.4985 47.6516C20.9587 40.4359 14.9751 29.6358 13.9289 17.7175L21.7554 10.6172H39.2417L47.0681 17.7175C46.0218 29.6358 40.0382 40.4359 30.4985 47.6516ZM26.6724 30.5591L39.6513 17.5805C40.3835 16.8483 41.5707 16.8483 42.3029 17.5805C43.0352 18.3127 43.0352 19.4999 42.3029 20.2321L27.9983 34.5367C27.6466 34.8884 27.1696 35.0859 26.6724 35.0859C26.1751 35.0859 25.6982 34.8884 25.3466 34.5367L20.2709 29.4609C19.5387 28.7287 19.5387 27.5415 20.2709 26.8093C21.0031 26.077 22.1902 26.077 22.9225 26.8093L26.6724 30.5591Z"
    fill={fillColor}
  />
</svg>
